body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  margin: 0;
  padding: 0;
}
html, body, * {
  box-sizing: border-box;
}
html, body {
  height: 100%;
  background-color:#464646;
  color:#fff;
  font-weight: 400;
  font-size: 13.3333px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

ion-icon {
  pointer-events: none;
}
