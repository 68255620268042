.row {
  display: block;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.cell {
  display: block;
  float: left;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  background-color: transparent;
  border: 1px solid black;
  transition: background-color 100ms ease-in-out;
  &.alive {
    background-color: rgba(#2ecc71, 0.5);
  }
}

.grid {
  overflow: auto;
  transition: width 100ms ease-in-out, height 100ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid black;
  background-color: #272727;
}
.gridContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  padding: 52px;
}
